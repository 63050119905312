<template>
  <div>
    <StateRenderer v-bind="rents">
      <b-alert
        v-if="rents.data.length === 0"
        show
        variant="secondary"
        class="text-center"
      >
        Nenhum aluguel realizado neste período
      </b-alert>
      <template v-else>
        <h4>
          <b> Total: {{ calculateTotal | formatCurrency }} </b>
        </h4>
        <b-table
          ref="rentsList"
          :fields="fields"
          :items="rents.data"
          striped
          hover
          :busy="rents.loading"
          responsive
          :tbody-tr-class="rowClass"
        >
          <template #table-colgroup="scope">
            <col v-for="field in scope.fields" :key="field.key" />
          </template>
          <template #cell(period.time)="{ item }">
            <span class="d-block">
              {{ item.period ? item.period.time : 'Período não disponível' }}
              <b-icon-info-circle
                v-b-tooltip.hover
                :title="
                  item.period && item.period.price
                    ? `Valor total do período: ${item.period.price}`
                    : 'Período não disponível'
                "
                font-scale="0.6"
                class="ml-1 align-super"
                style="vertical-align: super"
              ></b-icon-info-circle>
            </span>
          </template>
          <template #cell(createdAt)="{ item }">
            <span class="d-block">
              {{ getDate(item.createdAt) }}
            </span>
          </template>
          <template #cell(startsAt)="{ item }">
            <span class="d-block">
              {{ item.createdAt | formatHours }}
            </span>
          </template>
          <template #cell(endsAt)="{ item }">
            <span class="d-block">
              {{ calculateEndDate(item) | formatHours }}
            </span>
          </template>
          <template #cell(extraTime)="{ item }">
            <span class="d-block">
              {{ getExtraTime(item) }}
            </span>
          </template>
          <template #cell(pausedTime)="{ item }">
            <span class="d-block">
              {{ getPausedTime(item) }}
            </span>
          </template>
          <template #cell(exceededTime)="{ item }">
            <span class="d-block">
              {{ getExceededTime(item) }}
            </span>
          </template>
          <template #cell(rentTime)="{ item }">
            <span class="d-block">
              {{ getRentTime(item) - getPausedTime(item) }}
            </span>
          </template>
          <template #cell(timeCharged)="{ item }">
            <span class="d-block">
              {{ getRentTime(item) - getPausedTime(item) - getExtraTime(item) }}
            </span>
          </template>
          <template #cell(extraTimeReason)="{ item }">
            <span class="d-block">
              {{ getExtraTimeReason(item) }}
            </span>
          </template>
          <template #cell(products)="{ item }">
            <span class="d-block">
              {{ item.products.length }}
            </span>
          </template>
          <template #cell(cash)="{ item }">
            <span class="d-block">
              {{ calculateReceivables(item, 'cash') | formatCurrency }}
            </span>
          </template>
          <template #cell(debitCard)="{ item }">
            <span class="d-block">
              {{ calculateReceivables(item, 'debitCard') | formatCurrency }}
            </span>
          </template>
          <template #cell(creditCard)="{ item }">
            <span class="d-block">
              {{ calculateReceivables(item, 'creditCard') | formatCurrency }}
            </span>
          </template>
          <template #cell(pix)="{ item }">
            <span class="d-block">
              {{ calculateReceivables(item, 'pix') | formatCurrency }}
            </span>
          </template>
          <template #cell(status)="{ item }">
            <Status :value="getStatus(item)" />
          </template>
        </b-table>
      </template>
    </StateRenderer>
  </div>
</template>

<script>
import { BIconInfoCircle } from 'bootstrap-vue'
import { RENT_STATUS } from '@/constants'
import { reduce, pipe, filter } from 'lodash/fp'
import { formatCurrency } from '@/helpers/numbers'
import { formatDate, formatDateTime, formatHours } from '@/helpers/date'
import { format, addMinutes, differenceInMinutes } from 'date-fns'
import { calculateExtraTime, calculatePausedTime } from '@/helpers/rents'
import StateRenderer from '@/components/StateRenderer'
import ptBRLocale from 'date-fns/locale/pt-BR'
import periods from '@/services/modules/periods'
import Status from '@/components/Status'

export default {
  name: 'RentsReportList',
  filters: {
    formatCurrency,
    formatDate,
    formatHours,
    formatDateTime
  },
  components: {
    Status,
    StateRenderer,
    BIconInfoCircle
  },
  props: {
    rents: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      filter: {
        storeId: null,
        date: null
      },
      refreshComponent: 0
    }
  },
  computed: {
    fields: () => [
      {
        key: 'createdAt',
        label: 'Data'
      },
      {
        key: 'toy.name',
        label: 'Brinquedo'
      },
      {
        key: 'customer.name',
        label: 'Cliente'
      },
      {
        key: 'startsAt',
        label: 'Início'
      },
      {
        key: 'endsAt',
        label: 'Retorno'
      },
      {
        key: 'period.time',
        label: 'Período'
      },
      {
        key: 'extraTime',
        label: 'Adicional'
      },
      {
        key: 'pausedTime',
        label: 'Pausado'
      },
      {
        key: 'exceededTime',
        label: 'Excedido'
      },
      {
        key: 'rentTime',
        label: 'Permanência'
      },
      {
        key: 'timeCharged',
        label: 'Cobrado'
      },
      {
        key: 'extraTimeReason',
        label: 'Motivo tempo adicional'
      },
      {
        key: 'cash',
        label: 'Total Dinheiro'
      },
      {
        key: 'creditCard',
        label: 'Total Crédito'
      },
      {
        key: 'debitCard',
        label: 'Total Débito'
      },
      {
        key: 'pix',
        label: 'Total Pix'
      },

      {
        key: 'status',
        label: 'Status'
      }
    ],
    calculateTotal() {
      return reduce(
        (result, current) => {
          const total = reduce(
            (acc, cur) => (acc += cur.amount),
            0,
            current.payments
          )

          return (result += total)
        },
        0,
        this.rents.data
      )
    }
  },
  watch: {
    'rents.data': {
      handler() {
        this.recoverDeletedPeriods()
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.updateComponent()
    }, 50000)
    this.recoverDeletedPeriods()
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    async recoverDeletedPeriods() {
      const rents = this.rents.data || []
      // Find rents where period is missing but periodId exists
      const missingPeriodRents = rents.filter(
        (rent) => !rent.period && rent.periodId
      )
      // Get unique periodIds to fetch
      const periodIds = [
        ...new Set(missingPeriodRents.map((rent) => rent.periodId))
      ]
      // Fetch all missing periods
      const periodPromises = periodIds.map((periodId) =>
        periods.fetchPeriodById(periodId)
      )
      const periodsFetched = await Promise.all(periodPromises)
      // Map periods by their IDs for easy access
      const periodsById = {}
      periodsFetched.forEach((period) => {
        periodsById[period.id] = period
      })
      // Assign fetched periods to the respective rents
      missingPeriodRents.forEach((rent) => {
        if (periodsById[rent.periodId]) {
          rent.period = periodsById[rent.periodId]
        }
      })
    },
    rowClass(item) {
      if (item.status == 'canceled') {
        return 'table-danger'
      }
      return 'table-light'
    },
    updateComponent() {
      this.refreshComponent++

      this.rents.data.forEach((rent) => {
        if (rent.status === RENT_STATUS.PAUSED.value) {
          rent.pausedTime = this.getPausedTime(rent)
        }
      })
    },
    getDate(date) {
      return format(new Date(date), 'dd/MM/yyyy', {
        locale: ptBRLocale
      })
    },
    calculateEndDate(rent) {
      if (!rent.period) {
        return 'Período Incorreto'
      }
      const rentTime = this.getRentTime(rent)
      if (rentTime <= rent.period.time) {
        const startDate = new Date(rent.createdAt)
        return addMinutes(startDate, rent.period.time)
      }

      const startDate = new Date(rent.createdAt)
      return addMinutes(startDate, rentTime)
    },
    calculateReceivables(rent, type) {
      return pipe(
        filter((item) => item.type === type),
        reduce((result, current) => {
          return (result += current.amount)
        }, 0)
      )(rent.payments)
    },
    getPausedTime(rent) {
      if (!rent.pausedAt) {
        return 0
      }
      const pausedAt = new Date(rent.pausedAt)
      const now = new Date()
      if (rent.status === RENT_STATUS.PAUSED.value && !rent.endedAt) {
        return differenceInMinutes(now, pausedAt) + calculatePausedTime(rent)
      } else {
        return calculatePausedTime(rent)
      }
    },
    getExtraTime(rent) {
      return calculateExtraTime(rent)
    },
    getExtraTimeReason(rent) {
      return reduce(
        (result, current) =>
          result + (result === '' ? '' : ' / ') + current.reason,
        '',
        rent.extraTime
      )
    },
    getRentTime(rent) {
      let endDate

      if (rent.canceledAt) {
        endDate = new Date(rent.canceledAt)
      } else if (rent.endedAt) {
        endDate = new Date(rent.endedAt)
      } else {
        endDate = new Date()
      }

      const startDate = new Date(rent.createdAt)
      return differenceInMinutes(endDate, startDate)
    },
    getExceededTime(rent) {
      if (!rent.period) {
        return 'Período Incorreto'
      }
      const rentTime =
        this.getRentTime(rent) -
        this.getPausedTime(rent) -
        this.getExtraTime(rent)
      if (rentTime <= rent.period.time) {
        return 0
      }
      const extraTime = calculateExtraTime(rent)
      const result = rentTime - rent.period.time - extraTime
      return result >= 0 ? result : 0
    },
    getStatus(rent) {
      if (rent.canceledAt) {
        return RENT_STATUS.CANCELLED.value
      }

      if (rent.status == 'active') {
        return RENT_STATUS.ACTIVE.value
      }

      if (rent.endedAt) {
        return RENT_STATUS.ENDED.value
      }

      if (rent.pausedAt) {
        return RENT_STATUS.PAUSED.value
      }

      return RENT_STATUS.ACTIVE.value
    }
  }
}
</script>
