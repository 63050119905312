var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('StateRenderer',_vm._b({},'StateRenderer',_vm.rents,false),[(_vm.rents.data.length === 0)?_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"secondary"}},[_vm._v(" Nenhum aluguel realizado neste período ")]):[_c('h4',[_c('b',[_vm._v(" Total: "+_vm._s(_vm._f("formatCurrency")(_vm.calculateTotal))+" ")])]),_c('b-table',{ref:"rentsList",attrs:{"fields":_vm.fields,"items":_vm.rents.data,"striped":"","hover":"","busy":_vm.rents.loading,"responsive":"","tbody-tr-class":_vm.rowClass},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key})})}},{key:"cell(period.time)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(item.period ? item.period.time : 'Período não disponível')+" "),_c('b-icon-info-circle',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"ml-1 align-super",staticStyle:{"vertical-align":"super"},attrs:{"title":item.period && item.period.price
                  ? ("Valor total do período: " + (item.period.price))
                  : 'Período não disponível',"font-scale":"0.6"}})],1)]}},{key:"cell(createdAt)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.getDate(item.createdAt))+" ")])]}},{key:"cell(startsAt)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("formatHours")(item.createdAt))+" ")])]}},{key:"cell(endsAt)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("formatHours")(_vm.calculateEndDate(item)))+" ")])]}},{key:"cell(extraTime)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.getExtraTime(item))+" ")])]}},{key:"cell(pausedTime)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.getPausedTime(item))+" ")])]}},{key:"cell(exceededTime)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.getExceededTime(item))+" ")])]}},{key:"cell(rentTime)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.getRentTime(item) - _vm.getPausedTime(item))+" ")])]}},{key:"cell(timeCharged)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.getRentTime(item) - _vm.getPausedTime(item) - _vm.getExtraTime(item))+" ")])]}},{key:"cell(extraTimeReason)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm.getExtraTimeReason(item))+" ")])]}},{key:"cell(products)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(item.products.length)+" ")])]}},{key:"cell(cash)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.calculateReceivables(item, 'cash')))+" ")])]}},{key:"cell(debitCard)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.calculateReceivables(item, 'debitCard')))+" ")])]}},{key:"cell(creditCard)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.calculateReceivables(item, 'creditCard')))+" ")])]}},{key:"cell(pix)",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(_vm.calculateReceivables(item, 'pix')))+" ")])]}},{key:"cell(status)",fn:function(ref){
                  var item = ref.item;
return [_c('Status',{attrs:{"value":_vm.getStatus(item)}})]}}])})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }