<template>
  <div>
    <StoreSwitcher v-if="isAdmin" />
    <RentsReportFilter v-model="filter" @filter="loadRents" />
    <RentsReportList hide-warnings :rents="rents" @refresh="loadRents" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import withAsyncAction from '@/mixins/withAsyncAction'
import services from '@/services'
import RentsReportFilter from './RentsReportFilter'
import RentsReportList from '@/components/Reports/Rents/RentsReportList'
import { formatDate } from '@/helpers/date'
import StoreSwitcher from '@/components/Stores/StoreSwitcher'

export default {
  name: 'RentsReport',
  components: {
    RentsReportFilter,
    RentsReportList,
    StoreSwitcher
  },
  mixins: [
    withAsyncAction({
      key: 'rents',
      initialState: [],
      fetcher: {
        methodName: 'fetchRentsReport',
        handler: services.reports.fetchRentsReport
      }
    })
  ],
  data() {
    return {
      filter: {
        fromDate: formatDate(new Date()),
        toDate: formatDate(new Date())
      }
    }
  },
  computed: {
    ...mapGetters('store', ['currentStoreId', 'isAdmin'])
  },
  created() {
    this.loadRents()
  },
  methods: {
    loadRents() {
      const storeId = this.currentStoreId
      const { fromDate, toDate } = this.filter
      this.fetchRentsReport({ storeId, fromDate, toDate })
    }
  }
}
</script>
